import { PageNotFound, Permission } from '@campxdev/shared'
import {
  CourseBatches,
  CourseBooks,
  CourseDetails,
  CourseObjectives,
  CourseOutcomes,
  CourseUnitsAndTopics,
  DegreesPage,
  Handouts,
  ProgramDetailsPage,
  ProgramsPage,
  RegulationBatchesPage,
  SemestersPage,
} from 'pages/Academics'

export const academicRoutes = [
  {
    path: 'degrees',
    element: <DegreesPage />,
    permissionKey: Permission.CAN_ADMIN_DEGREES_VIEW,
  },
  {
    path: 'semesters',
    element: <SemestersPage />,
    permissionKey: Permission.CAN_ADMIN_SEMESTERS_VIEW,
  },
  {
    path: 'regulation-batches',
    element: <RegulationBatchesPage />,
    permissionKey: Permission.CAN_ADMIN_REGULATION_BATCHES_VIEW,
  },

  {
    path: 'programs',
    element: <ProgramsPage />,
    permissionKey: Permission.CAN_ADMIN_PROGRAMS_VIEW,
  },
  {
    path: 'programs/:programId',
    element: <ProgramDetailsPage />,
    permissionKey: Permission.CAN_ADMIN_PROGRAMS_VIEW,
  },

  // Program Course Routes
  {
    element: <CourseDetails />,
    path: 'programs/:programId/course/:courseId',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <CourseObjectives />,
    path: 'programs/:programId/course/:courseId/objectives',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <CourseOutcomes />,
    path: 'programs/:programId/course/:courseId/outcomes',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <CourseBatches />,
    path: 'programs/:programId/course/:courseId/subject-batches-config',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_BATCH_CONFIG_VIEW,
  },
  {
    element: <CourseBooks />,
    path: 'programs/:programId/course/:courseId/books',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <CourseUnitsAndTopics />,
    path: 'programs/:programId/course/:courseId/units-and-topics',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <Handouts />,
    path: 'programs/:programId/course/:courseId/handouts',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
