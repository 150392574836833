import { PageNotFound, Permission } from '@campxdev/shared'
import { DataImportDetailsPage, DataImportsList } from 'pages/DataImports'

export const dataImports = [
  {
    index: true,
    element: <DataImportsList />,
    permissionKey: Permission.CAN_ADMIN_DATA_IMPORTS_VIEW,
  },
  {
    path: ':id/details',
    element: <DataImportDetailsPage />,
    permissionKey: Permission.CAN_ADMIN_DATA_IMPORTS_VIEW,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
