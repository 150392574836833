import { PageNotFound, Permission, withRouteWrapper } from '@campxdev/shared'
import { AppLayout } from 'layouts/AppLayout'
import { MainLayout } from 'layouts/MainLayout'
import { DataExportsPage } from 'pages/DataImports'
import { People } from 'pages/People'
import { Navigate, RouteObject } from 'react-router-dom'
import { academicRoutes } from './subRoutes/academics'
import { coursesRoutes } from './subRoutes/courses'
import { dataImports } from './subRoutes/dataImports'
import { manageUsers } from './subRoutes/manageUsers'
import { organizationRoutes } from './subRoutes/organization'
import { studentsRoutes } from './subRoutes/students'

const baseRoutes = [
  {
    index: true,
    element: <Navigate to={'org/general'} />,
    permissionKey: Permission.CAN_ADMIN_MANAGE_ORG_SETTINGS,
  },
  {
    path: 'org/*',
    children: organizationRoutes,
  },
  {
    path: 'academics/*',
    children: academicRoutes,
  },
  {
    path: 'courses/*',
    children: coursesRoutes,
  },
  {
    path: 'students/*',
    children: studentsRoutes,
  },
  {
    path: 'manage-users/*',
    children: manageUsers,
  },
  {
    path: 'data-imports/*',
    children: dataImports,
  },
  {
    path: 'application-profiles',
    element: <People />,
    permissionKey: Permission.CAN_MANAGE_ADMIN_PROFILE_PERMISSIONS_VIEW,
  },
  {
    path: 'data-exports',
    element: <DataExportsPage />,
    permissionKey: Permission.CAN_ADMIN_DATA_IMPORTS_VIEW,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]

export const mainRoutes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      {
        element: <MainLayout />,
        path: '/',
        children: withRouteWrapper(baseRoutes),
      },
    ],
  },
]
