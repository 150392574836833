import { ErrorBoundary, LayoutWrapper } from '@campxdev/shared'
import { Outlet } from 'react-router-dom'
import { mainMenu } from './mainMenu'

export function MainLayout() {
  return (
    <LayoutWrapper menu={mainMenu}>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </LayoutWrapper>
  )
}
