import { PageNotFound, Permission } from '@campxdev/shared'
import { StudentDetails, StudentsPage, UpdateStudent } from 'pages/Students'

export const studentsRoutes = [
  {
    element: <StudentsPage />,
    path: 'all-students',
    permissionKey: Permission.CAN_ADMIN_STUDENTS_VIEW,
  },
  {
    element: <StudentDetails />,
    path: 'all-students/:studentId/details',
    permissionKey: Permission.CAN_ADMIN_STUDENT_INFO_VIEW,
  },
  {
    element: <UpdateStudent />,
    path: 'all-students/:studentId/edit',
    permissionKey: Permission.CAN_ADMIN_STUDENTS_EDIT,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
