import { PageNotFound, Permission } from '@campxdev/shared'
import { DepartmentsPage } from 'pages/Academics/Departments'
import { DesignationsPage } from 'pages/ManageUsers/Designations'
import {
  GeneralSettings,
  InstitutionView,
  InstitutionsPage,
} from 'pages/OrgSettings'

export const organizationRoutes = [
  {
    path: 'general',
    element: <GeneralSettings />,
    permissionKey: Permission.CAN_ADMIN_MANAGE_ORG_SETTINGS,
  },
  {
    element: <InstitutionsPage />,
    path: 'institutions',
    permissionKey: Permission.CAN_ADMIN_INSTITUTIONS_VIEW,
  },
  {
    element: <InstitutionView />,
    path: 'institutions/institution-view/:institutionId',
    permissionKey: Permission.CAN_ADMIN_INSTITUTION_CONFIGURATION_VIEW,
  },
  {
    path: 'departments',
    element: <DepartmentsPage />,
    permissionKey: Permission.CAN_ADMIN_DEPARTMENTS_VIEW,
  },
  {
    element: <DesignationsPage />,
    path: 'designations',
    permissionKey: Permission.CAN_ADMIN_DESIGNATIONS_VIEW,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
