import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import MenuPopoverProvider from 'context/MenuPopover'
import { useRoutes } from 'react-router-dom'
import { mainRoutes } from 'routes/mainRoutes'

function App() {
  const app = useRoutes(mainRoutes)

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MenuPopoverProvider>{app}</MenuPopoverProvider>
      </LocalizationProvider>
    </>
  )
}

export default App
