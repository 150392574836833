import { lazy } from 'react'
export * from './CourseForm'

export const AllCoursesPage = lazy(() => import('./AllCoursesPage'))
export const CourseDetails = lazy(() => import('./CourseDetails'))
export const CourseOutcomes = lazy(() => import('./Outcomes'))
export const CourseObjectives = lazy(() => import('./Objectives'))
export const CourseUnitsAndTopics = lazy(() => import('./UnitsAndTopics'))
export const CourseBooks = lazy(() => import('./Books'))
export const CourseBatches = lazy(() => import('./CourseBatches'))
export const Handouts = lazy(() => import('./Handouts'))
