import { Permission } from '@campxdev/shared'
import {
  AcademicsIcon,
  CourseRegistrationIcon,
  ManageUserIcon,
  OrgIcon,
  PeopleIcon,
  StudentsIcon,
} from './icons'

export const mainMenu = [
  {
    title: 'Org. Settings',
    path: 'org',
    icon: OrgIcon,
    permissionKey: Permission.CAN_ADMIN_MANAGE_ORG_SETTINGS,
    children: [
      {
        path: '/general',
        title: 'General Settings',
        permissionKey: Permission.CAN_ADMIN_MANAGE_ORG_SETTINGS,
      },
      {
        path: '/institutions',
        title: 'Institutions',
        permissionKey: Permission.CAN_ADMIN_INSTITUTIONS_VIEW,
      },
      {
        title: 'Departments',
        path: '/departments',
        permissionKey: Permission.CAN_ADMIN_DEPARTMENTS_VIEW,
      },
      {
        title: 'Designations',
        path: '/designations',
        permissionKey: Permission.CAN_ADMIN_DESIGNATIONS_VIEW,
      },
    ],
  },
  {
    title: 'Academics',
    path: '/academics',
    icon: AcademicsIcon,
    children: [
      {
        title: 'Degrees',
        path: '/degrees',
        permissionKey: Permission.CAN_ADMIN_DEGREES_VIEW,
      },
      {
        title: 'Programs',
        path: '/programs',
        permissionKey: Permission.CAN_ADMIN_PROGRAMS_VIEW,
      },
      {
        title: 'Semesters',
        path: '/semesters',
        permissionKey: Permission.CAN_ADMIN_SEMESTERS_VIEW,
      },
      {
        title: 'Regulation Batches',
        path: '/regulation-batches',
        permissionKey: Permission.CAN_ADMIN_REGULATION_BATCHES_VIEW,
      },
    ],
  },
  {
    title: 'Courses',
    path: '/courses',
    icon: CourseRegistrationIcon,
    children: [
      {
        title: 'Course Types',
        path: '/course-types',
        permissionKey: Permission.CAN_ADMIN_SUBJECT_TYPES_VIEW,
      },
      {
        title: 'All Courses',
        path: '/all-courses',
        permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
      },
    ],
  },
  {
    title: 'Students',
    path: '/students',
    icon: StudentsIcon,
    permissionKey: Permission.CAN_ADMIN_STUDENTS_VIEW,
    children: [
      {
        title: 'All Students',
        path: '/all-students',
        permissionKey: Permission.CAN_ADMIN_STUDENTS_VIEW,
      },
    ],
  },
  {
    title: 'Manage Users',
    path: '/manage-users',
    icon: ManageUserIcon,
    children: [
      {
        title: 'All Users',
        path: '/users',
        permissionKey: Permission.CAN_ADMIN_USERS_VIEW,
      },

      {
        title: 'Profiles',
        path: '/profiles',
        permissionKey: Permission.CAN_MANAGE_USER_PROFILES,
      },
    ],
  },
  {
    title: 'People',
    path: 'application-profiles',
    icon: PeopleIcon,
    permissionKey: Permission.CAN_MANAGE_ADMIN_PROFILE_PERMISSIONS_VIEW,
  },
  {
    title: 'Data Imports',
    path: 'data-imports',
    permissionKey: Permission.CAN_ADMIN_DATA_IMPORTS_VIEW,
  },
  {
    title: 'Data Exports',
    path: 'data-exports',
    permissionKey: Permission.CAN_ADMIN_DATA_IMPORTS_VIEW,
  },
]
