export const ClassroomsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="presentation_1_"
        data-name="presentation (1)"
        d="M15.333,9.333h-.667v-6A3.337,3.337,0,0,0,11.333,0H4.667A3.337,3.337,0,0,0,1.333,3.333v6H.667a.667.667,0,1,0,0,1.333H7.333v2.667H6a2,2,0,0,0-2,2,.667.667,0,0,0,1.333,0A.667.667,0,0,1,6,14.667h4a.667.667,0,0,1,.667.667.667.667,0,0,0,1.333,0,2,2,0,0,0-2-2H8.667V10.667h6.667a.667.667,0,0,0,0-1.333Zm-12.667-6a2,2,0,0,1,2-2h6.667a2,2,0,0,1,2,2v6H2.667Z"
        fill="#fff"
      />
    </svg>
  )
}

export const CourseRegistrationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.189"
      height="16.389"
      viewBox="0 0 15.189 16.389"
    >
      <g
        id="vuesax_linear_document-text"
        data-name="vuesax/linear/document-text"
        transform="translate(-492 -251.4)"
      >
        <g id="document-text" transform="translate(492 252)">
          <path
            id="Vector"
            d="M12.655,3.8v7.594c0,2.278-1.055,3.8-3.515,3.8H3.515C1.055,15.189,0,13.67,0,11.392V3.8C0,1.519,1.055,0,3.515,0H9.139C11.6,0,12.655,1.519,12.655,3.8Z"
            transform="translate(1.267)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0V1.394A1.4,1.4,0,0,0,1.394,2.788H2.788"
            transform="translate(9.677 2.018)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H4"
            transform="translate(4.042 8.397)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H8"
            transform="translate(3.593 11.605)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H15.189V15.189H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  )
}

export const ManageUserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.739"
      height="15.774"
      viewBox="0 0 14.739 15.774"
    >
      <g
        id="vuesax_linear_user-tick"
        data-name="vuesax/linear/user-tick"
        transform="translate(-299.967 -187.45)"
      >
        <g id="user-tick" transform="translate(300 188)">
          <path
            id="Vector"
            d="M7.337,3.668A3.668,3.668,0,1,1,3.668,0,3.668,3.668,0,0,1,7.337,3.668Z"
            transform="translate(3.151)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.1"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,5.136C0,2.3,2.825,0,6.3,0A7.624,7.624,0,0,1,8.327.271"
            transform="translate(0.517 9.538)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.1"
          />
          <g id="Group" transform="translate(8.287 8.804)">
            <g id="Group_138" data-name="Group 138" transform="translate(0 0)">
              <path
                id="Vector-3"
                data-name="Vector"
                d="M5.87,2.935a2.877,2.877,0,0,1-.426,1.511,2.636,2.636,0,0,1-.58.69,2.865,2.865,0,0,1-1.93.734A2.913,2.913,0,0,1,.426,4.446,2.877,2.877,0,0,1,0,2.935a2.935,2.935,0,0,1,5.87,0Z"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.1"
              />
              <path
                id="Vector-4"
                data-name="Vector"
                d="M0,.719l.726.726L2.289,0"
                transform="translate(1.79 2.216)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.1"
              />
            </g>
          </g>
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H14.674V14.674H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  )
}

export const PeopleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.171"
      height="16.337"
      viewBox="0 0 16.171 16.337"
    >
      <g
        id="vuesax_linear_people"
        data-name="vuesax/linear/people"
        transform="translate(-619.429 -312.425)"
      >
        <g id="people" transform="translate(619.979 312.975)">
          <g id="Group" transform="translate(10.061 0)">
            <path
              id="Vector"
              d="M2.016,3.9a.457.457,0,0,0-.143,0,1.951,1.951,0,1,1,.143,0Z"
              transform="translate(0 0)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.1"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M.023,3.729A4.254,4.254,0,0,0,3,3.186,1.443,1.443,0,0,0,3,.6,4.28,4.28,0,0,0,0,.068"
              transform="translate(1.215 5.661)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.1"
            />
          </g>
          <g id="Group-2" data-name="Group" transform="translate(0 0)">
            <path
              id="Vector-3"
              data-name="Vector"
              d="M1.88,3.9a.457.457,0,0,1,.143,0,1.951,1.951,0,1,0-.143,0Z"
              transform="translate(1.115 0)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.1"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M3.773,3.729A4.254,4.254,0,0,1,.8,3.186,1.443,1.443,0,0,1,.8.6a4.28,4.28,0,0,1,3-.536"
              transform="translate(0 5.661)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.1"
            />
          </g>
          <g id="Group-3" data-name="Group" transform="translate(4.552 5.639)">
            <path
              id="Vector-5"
              data-name="Vector"
              d="M2.016,3.9a.457.457,0,0,0-.143,0,1.951,1.951,0,1,1,.143,0Z"
              transform="translate(0.979 0)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.1"
            />
            <path
              id="Vector-6"
              data-name="Vector"
              d="M.8.6a1.443,1.443,0,0,0,0,2.582,4.286,4.286,0,0,0,4.393,0A1.443,1.443,0,0,0,5.192.6,4.321,4.321,0,0,0,.8.6Z"
              transform="translate(0 5.673)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.1"
            />
          </g>
          <path
            id="Vector-7"
            data-name="Vector"
            d="M0,0H15.053V15.053H0Z"
            transform="translate(0.018 0.022)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  )
}

export const SettingsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="settings-sliders" transform="translate(0 0)">
        <path
          id="Path_17828"
          data-name="Path 17828"
          d="M.667,3.167H2.491a2.485,2.485,0,0,0,4.8,0h8.046a.667.667,0,1,0,0-1.333H7.287a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,1,0,0,1.333ZM4.889,1.333A1.167,1.167,0,1,1,3.722,2.5,1.167,1.167,0,0,1,4.889,1.333Z"
          fill="#fff"
        />
        <path
          id="Path_17829"
          data-name="Path 17829"
          d="M15.333,10.083H13.509a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,1,0,0,1.333H8.713a2.485,2.485,0,0,0,4.8,0h1.824a.667.667,0,1,0,0-1.333Zm-4.222,1.833a1.167,1.167,0,1,1,1.167-1.167,1.167,1.167,0,0,1-1.167,1.167Z"
          transform="translate(0 -2.75)"
          fill="#fff"
        />
        <path
          id="Path_17830"
          data-name="Path 17830"
          d="M15.333,18.333H7.287a2.485,2.485,0,0,0-4.8,0H.667a.667.667,0,0,0,0,1.333H2.491a2.485,2.485,0,0,0,4.8,0h8.046a.667.667,0,0,0,0-1.333ZM4.889,20.167A1.167,1.167,0,1,1,6.055,19a1.167,1.167,0,0,1-1.167,1.167Z"
          transform="translate(0 -5.5)"
          fill="#fff"
        />
      </g>
    </svg>
  )
}

export const AcademicsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14.682"
      viewBox="0 0 16 14.682"
    >
      <path
        id="graduation-cap_1_"
        data-name="graduation-cap (1)"
        d="M14.693,23.689,9.777,21.347a3.484,3.484,0,0,0-3.522-.03L1.284,23.689c-.019.009-.038.019-.056.03a2.461,2.461,0,0,0,.056,4.3l1.371.653v3.267A3.338,3.338,0,0,0,5,35.128a10.371,10.371,0,0,0,2.987.408,10.372,10.372,0,0,0,2.987-.4,3.338,3.338,0,0,0,2.346-3.185V28.674l1.333-.637v5.5a.667.667,0,1,0,1.333,0v-8A2.232,2.232,0,0,0,14.693,23.689Zm-2.7,8.256a2,2,0,0,1-1.4,1.909,9.061,9.061,0,0,1-2.6.348,9.061,9.061,0,0,1-2.6-.348,2,2,0,0,1-1.4-1.909V29.311L6.2,30.364a3.515,3.515,0,0,0,1.8.493,3.333,3.333,0,0,0,1.718-.463l2.267-1.083Zm2.133-5.127-5.028,2.4a2.2,2.2,0,0,1-2.26-.03l-4.919-2.34a1.128,1.128,0,0,1-.025-1.972l5-2.387a2.2,2.2,0,0,1,2.26.03l4.916,2.342a1.152,1.152,0,0,1,.593.993,1.133,1.133,0,0,1-.533.963Z"
        transform="translate(0.011 -20.854)"
        fill="#fff"
      />
    </svg>
  )
}

export const OrgIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.2"
      height="16.214"
      viewBox="0 0 16.2 16.214"
    >
      <path
        id="school_1_"
        data-name="school (1)"
        d="M12.667,4H9.547a2.633,2.633,0,0,0-.881-.408V3.331l2.353-1.1a.667.667,0,0,0,0-1.133L8.887.1a1.091,1.091,0,0,0-1.553.992v2.5A2.625,2.625,0,0,0,6.453,4H3.333A3.337,3.337,0,0,0,0,7.331V14a2,2,0,0,0,2,2H14a2,2,0,0,0,2-2V7.331A3.337,3.337,0,0,0,12.667,4Zm1.877,2.667H13.1a2.011,2.011,0,0,1-1.414-.586l-.748-.747h1.724A2,2,0,0,1,14.544,6.665ZM3.333,5.331H5.057l-.748.747A2.011,2.011,0,0,1,2.9,6.665H1.456A2,2,0,0,1,3.333,5.331Zm5.333,9.333H7.333v-2a.667.667,0,1,1,1.333,0Zm5.333,0H10v-2a2,2,0,1,0-4,0v2H2A.667.667,0,0,1,1.333,14V8H2.9a3.312,3.312,0,0,0,2.357-.977l1.805-1.8a1.333,1.333,0,0,1,1.885,0l1.805,1.8A3.312,3.312,0,0,0,13.1,8h1.562v6A.667.667,0,0,1,14,14.665ZM9.333,8A1.333,1.333,0,1,1,8,6.665,1.333,1.333,0,0,1,9.333,8Z"
        transform="translate(0.1 0.116)"
        fill="#fff"
        stroke="#121212"
        strokeWidth="0.2"
      />
    </svg>
  )
}

export const StudentsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="users_3_"
        data-name="users (3)"
        d="M5,8.667a3,3,0,1,1,3-3,3,3,0,0,1-3,3ZM5,4A1.667,1.667,0,1,0,6.667,5.667,1.667,1.667,0,0,0,5,4Zm5,11.333V15A5,5,0,0,0,0,15v.333a.667.667,0,0,0,1.333,0V15a3.667,3.667,0,0,1,7.333,0v.333a.667.667,0,0,0,1.333,0ZM16,12A4.667,4.667,0,0,0,8.222,8.522a.667.667,0,1,0,.889.993A3.333,3.333,0,0,1,14.667,12,.667.667,0,0,0,16,12ZM11.667,6a3,3,0,1,1,3-3,3,3,0,0,1-3,3Zm0-4.667A1.667,1.667,0,1,0,13.333,3,1.667,1.667,0,0,0,11.667,1.333Z"
        fill="#fff"
      />
    </svg>
  )
}
