import { axios } from '@campxdev/shared'
import { AxiosRequestConfig } from 'axios'
import moment from 'moment'
import * as XLSX from 'xlsx'
import { Course, SqlCourse } from './courses.service'
import { Program } from './programs.service'
import { Regulation } from './regulation-batches.service'
import { Semester } from './semester.service'
import { SubjectType } from './subject-types.service'

export type Subjects = {
  count?: number
  subjects?: Subject[]
}

export type Subject = {
  id?: number
  courseId?: number
  branchCode?: string
  semNo?: number
  subjectCode?: string
  regulationId?: number
  name?: string
  subjectTypeId?: number
  replacement?: number
  intMax?: number
  extMax?: number
  refCode?: string
  hasInternal?: boolean
  hasExternal?: boolean
  relatedSubjectId?: number
  about?: string
  syllabusResourceId?: number
  elective?: boolean
  electGrp?: string
  credits?: string
  subOrder?: number
  isHonorsMinorsCourse?: boolean
  honorsMinorsType?: string
  isSatisfactoryCourse?: boolean
  isGradedCourse?: boolean
  createdById?: number
  updatedById?: number
  createdAt?: Date
  updatedAt?: Date
  subjectType?: SubjectType
  course?: SqlCourse
  program?: Program
  regulation?: Regulation
  syllabusUrl?: string
}

export type SubjectFilters = {
  courses: Course[]
  programs: Program[]
  regulations: Regulation[]
  subjectTypes: SubjectType[]
  semesters: Semester[]
}

export type GroupedSubjects = {
  regularSubjects?: Record<string, Subject[]>
  honorsMinorsSubjects?: Record<string, Subject[]>
}

const SUBJECTS_ENDPOINT = '/admin/subjects'

export class SubjectsService {
  static async getSubjects(params?: any) {
    const res = await axios.get<Subjects>(SUBJECTS_ENDPOINT, { params })
    return res.data
  }

  static async getGroupedSubjects(params?: any) {
    const res = await axios.get<GroupedSubjects>(
      `${SUBJECTS_ENDPOINT}/grouped`,
      {
        params,
      },
    )
    return res.data
  }

  static async getSubjectFilters() {
    const res = await axios.get<SubjectFilters>(`${SUBJECTS_ENDPOINT}/filters`)
    return res.data
  }

  static async getSubjectDetails(id: number | string) {
    const res = await axios.get<Subject>(`${SUBJECTS_ENDPOINT}/${id}`)
    return res.data
  }

  static async deleteSubject(id: number) {
    const res = await axios.delete(`${SUBJECTS_ENDPOINT}/${id}`)
    return res.data
  }

  static importSubjectsUrl = `${SUBJECTS_ENDPOINT}/import`

  static async createUpdateSubject(props: { id?: number; body: any }) {
    const config: AxiosRequestConfig = {
      method: props.id ? 'PUT' : 'POST',
      url: `${SUBJECTS_ENDPOINT}${props.id ? `/${props.id}` : ''}`,
      data: props.body,
    }
    const res = await axios(config)
    return res.data
  }

  static async exportToExcel(query: Record<string, string>) {
    const { limit, offset, ...params } = query
    const { subjects: data } = await this.getSubjects(params)

    let excelData = data?.map((item, index) => ({
      'Sl No.': index + 1,
      'Degree Name': item?.course?.courseName,
      'Program Code': item?.branchCode,
      Semester: item?.semNo,
      Regulation: item?.regulation?.name,
      'Course Code': item?.subjectCode,
      'Course Name': item?.name?.toUpperCase(),
      'Course Type': item?.subjectType?.type ?? '',
      Elective: item?.elective ? 'True' : 'False',
      'Is Honors And Minors Course': item?.isHonorsMinorsCourse
        ? 'True'
        : 'False',
      'Honors And Minors Type': item.honorsMinorsType,
      'is Satisfactory Course': item?.isSatisfactoryCourse ? 'True' : 'False',
      'Has Externals': item?.hasExternal ? 'True' : 'False',
      'External Maximum': item?.extMax,
      'Has Internals': item?.hasInternal ? 'True' : 'False',
      'Internal Maximum': item?.intMax,
      Credits: item?.credits,
      'Last Updated': item?.updatedAt
        ? moment(item?.updatedAt).format('DD-MM-YYYY')
        : '',
    }))

    const fileName = `AllCourses.xlsx`

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData, {
      header: Object.keys(excelData[0] || {}),
    })
    ws['!cols'] = [
      { wch: 5 },
      { wch: 20 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
      { wch: 15 },
      { wch: 30 },
      { wch: 15 },
      { wch: 10 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
    ]
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(
      wb,
      ws,
      `${moment().format('DD-MM-YYYY')} - All Courses`,
    )
    XLSX.writeFile(wb, fileName)
  }

  static async updateSubjectDetails(
    id: number,
    body: { file?: any; about?: string },
  ) {
    let form = new FormData()
    form.append('file', body.file)
    form.append('about', body.about)

    await axios.put(`/square/subjects/${id}/subjectInfo`, form)
  }

  // subject books apis
  static async createUpdateSubjectBook(props: { id?: number; body: any }) {
    const config: AxiosRequestConfig = {
      method: props.id ? 'PUT' : 'POST',
      url: `/square/subject-books${props.id ? `/${props.id}` : ''}`,
      data: props.body,
    }

    const res = await axios(config)
    return res.data
  }

  static async deleteSubjectBook(id: number) {
    const res = await axios.delete(`/square/subject-books/${id}`)
    return res.data
  }

  static async getSubjectBooks(params?: {
    regulationId: number
    subjectCode: string
  }) {
    const res = await axios.get<any>('/square/subject-books', { params })
    return res.data
  }

  // Subject handouts apis

  static async fetchHandouts(params) {
    const res = await axios.get(`/square/handouts`, {
      params: {
        ...params,
      },
    })
    return res.data
  }

  static async deleteHandouts(id: number) {
    const res = await axios.delete(`/square/handouts/${id}`)
    return res.data
  }

  static async createUpdateHandouts({ id, ...postBody }) {
    const config: AxiosRequestConfig = {
      method: id ? 'PUT' : 'POST',
      url: id ? `/square/handouts/${id}` : `/square/handouts`,
      data: postBody,
    }
    const res = await axios(config)
    return res.data
  }

  static async uploadHandoutFiles({ fileData, name }) {
    const res = await axios.post(`/square/resources/handouts`, fileData)
    return { ...res.data, name }
  }

  // Subject topics apis
  static async fetchSubjectTopics(params: {
    regulationId: number
    subjectCode: string
  }) {
    const res = await axios.get(`/square/subject-topics`, {
      params: {
        ...params,
      },
    })
    return res.data
  }

  static async createUpdateSubjectTopic({ id, body }) {
    const config: AxiosRequestConfig = {
      method: id ? 'PUT' : 'POST',
      url: id ? `/square/subject-topics/${id}` : `/square/subject-topics`,
      data: body,
    }
    const res = await axios(config)
    return res.data
  }

  static async deleteSubjectTopic(id: number) {
    const res = await axios.delete(`/square/subject-topics/${id}`)
    return res.data
  }

  static subjectTopicsImportUrl = `/square/subject-topics/import`

  // Subject objectives apis
  static async fetchSubjectObjectives(params: {
    regulationId: number
    subjectCode: string
  }) {
    const res = await axios.get(`/square/subject-objectives`, {
      params: {
        ...params,
      },
    })
    return res.data
  }

  static async createUpdateSubjectObjectives({ id, body }) {
    const config: AxiosRequestConfig = {
      method: id ? 'PUT' : 'POST',
      url: id
        ? `/square/subject-objectives/${id}`
        : `/square/subject-objectives`,
      data: body,
    }
    const res = await axios(config)
    return res.data
  }

  static async deleteSubjectObjective(id: number) {
    const res = await axios.delete(`/square/subject-objectives/${id}`)
    return res.data
  }

  // Subject outcomes apis
  static async fetchSubjectOutcomes(params: {
    regulationId: number
    subjectCode: string
  }) {
    const res = await axios.get(`/square/subject-outcomes`, {
      params: {
        ...params,
      },
    })
    return res.data
  }

  static async createUpdateSubjectOutcomes({ id, body }) {
    const config: AxiosRequestConfig = {
      method: id ? 'PUT' : 'POST',
      url: id ? `/square/subject-outcomes/${id}` : `/square/subject-outcomes`,
      data: body,
    }
    const res = await axios(config)
    return res.data
  }

  static async deleteSubjectOutcome(id: number) {
    const res = await axios.delete(`/square/subject-outcomes/${id}`)
    return res.data
  }
}
