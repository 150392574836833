import {
  ActionButton,
  axiosErrorToast,
  FormSingleCheckbox,
  FormSingleSelect,
  FormTextField,
  Spinner,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Card, Stack } from '@mui/material'
import { keyBy } from 'lodash'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { Subject, SubjectsService } from 'services/apis/subjects.service'
import * as yup from 'yup'

const allCoursesSchema = yup.object().shape({
  courseId: yup.string().required('Degree is required'),
  regulationId: yup.string().required('Regulation is required'),
  branchCode: yup.string().required('Program Code is required'),
  semNo: yup.string().when('isHonorsMinorsCourse', {
    is: false,
    then: (schema) => schema.required('Semester is required'),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  honorsMinorsType: yup.string().when('isHonorsMinorsCourse', {
    is: true,
    then: (schema) => schema.required('Course Type is required'),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  name: yup.string().required('Course Name is required'),
  subjectTypeId: yup.string().required('Course Type is required'),
  subjectCode: yup.string().required('Course Code is required'),
  refCode: yup.string().required('Reference Code is required'),
  credits: yup.number().min(0).required('Credits is required'),
  subOrder: yup.number().min(0).required('Order is required'),
  extMax: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0)
    .nullable()
    .when('hasExternal', {
      is: true,
      then: (schema) => schema.required('External Marks is required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  intMax: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0)
    .nullable()
    .when('hasInternal', {
      is: true,
      then: (schema) => schema.required('Internal Marks is required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  electGrp: yup
    .string()
    .nullable()
    .when('elective', {
      is: true,
      then: (schema) => schema.required('External Group is required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
})

const allCoursesEditSchema = yup.object().shape({
  name: yup.string().required('Course Name is required'),
  subjectTypeId: yup.string().required('Course Type is required'),
  subjectCode: yup.string().required('Course Code is required'),
  refCode: yup.string().required('Reference Code is required'),
  credits: yup.number().min(0).required('Credits is required'),
  subOrder: yup.number().min(0).required('Order is required'),
  extMax: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0)
    .nullable()
    .when('hasExternal', {
      is: true,
      then: (schema) => schema.required('External Marks is required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  intMax: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(0)
    .nullable()
    .when('hasInternal', {
      is: true,
      then: (schema) => schema.required('Internal Marks is required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
  electGrp: yup
    .string()
    .nullable()
    .when('elective', {
      is: true,
      then: (schema) => schema.required('External Group is required'),
      otherwise: (schema) => schema.nullable().notRequired(),
    }),
})

export const honorsMinorsTypes = {
  HONORS: 'HONORS',
  MINORS: 'MINORS',
}

type CoursePostFormProps = {
  data?: Subject
  close: () => void
  onSuccess?: () => void
  preFillData?: {
    courseId?: number
    branchCode?: string
    regulationId?: number
  }
}

export function CoursePostForm({
  data,
  close,
  onSuccess,
  preFillData,
}: CoursePostFormProps) {
  const { data: masterData, isLoading: loadingMasterData } = useQuery({
    queryKey: ['subject-filters', 'post'],
    queryFn: async () => {
      const res = await SubjectsService.getSubjectFilters()
      const courseById = keyBy(res?.courses || [], (v) => v.id)
      return {
        ...res,
        courseMap: courseById,
      }
    },
  })

  const { control, watch, handleSubmit } = useForm<Subject>({
    defaultValues: {
      ...(data ? data : preFillData ? preFillData : {}),
    },
    resolver: yupResolver(data ? allCoursesEditSchema : allCoursesSchema),
  })

  const { mutate, isLoading: updatingSubject } = useMutation(
    SubjectsService.createUpdateSubject,
    {
      onSuccess: () => {
        onSuccess && onSuccess()
        close()
        toast.success(`Course  ${data ? 'updated' : 'created'} successfully`)
      },
      onError: (err) => {
        axiosErrorToast(err)
      },
    },
  )

  const onSubmit = async (formData: any) => {
    const postBody = {
      ...formData,
      replacement: 0,
      elective: formData.elective ? true : false,
      electGrp: formData.elective ? formData.electGrp : null,
      isHonorsMinorsCourse: !!formData.isHonorsMinorsCourse,
      isSatisfactoryCourse: !!formData.isSatisfactoryCourse,
      isSatisfactoryWithExternal: !!formData.isSatisfactoryWithExternal,
      isAuditCourse: !!formData.isAuditCourse,
      isGradedCourse: !!formData.isGradedCourse,
      hasInternal: !!formData.hasInternal,
      hasExternal: !!formData.hasExternal,
    }

    mutate({ id: data?.id, body: postBody })
  }

  if (loadingMasterData) return <Spinner />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3.5}>
        {!data && (
          <>
            <FormSingleSelect
              label="Degree"
              name="courseId"
              control={control}
              options={masterData?.courses?.map((item) => ({
                label: item?.courseName,
                value: item?.uniqueId,
              }))}
              required
              disabled={!!preFillData?.courseId}
            />
            <FormSingleSelect
              required
              name="branchCode"
              control={control}
              label="Program"
              options={masterData?.programs
                ?.filter(
                  (item) =>
                    watch('courseId') &&
                    watch('courseId') ===
                      masterData?.courseMap[item.courseId]?.uniqueId,
                )
                ?.map((item) => ({
                  label: item.branchName,
                  value: item.branchCode,
                }))}
              disabled={!!preFillData?.branchCode}
            />
            <FormSingleSelect
              label="Regulation"
              name="regulationId"
              control={control}
              options={masterData?.regulations?.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))}
              required
            />
            <Card sx={{ padding: '5px 1rem' }}>
              <FormSingleCheckbox
                label="Is Honors Minors"
                control={control}
                name={'isHonorsMinorsCourse'}
              />
            </Card>
            {watch('courseId') && !watch('isHonorsMinorsCourse') && (
              <FormSingleSelect
                required
                name="semNo"
                control={control}
                label="Semester"
                options={masterData?.semesters
                  ?.sort((a, b) => a.semNo - b.semNo)
                  ?.filter(
                    (item) =>
                      watch('courseId') &&
                      watch('courseId') ===
                        masterData?.courseMap[item.courseId]?.uniqueId,
                  )
                  ?.map((item) => ({
                    label: item.semNo,
                    value: item.semNo,
                  }))}
              />
            )}
            {watch('isHonorsMinorsCourse') && (
              <FormSingleSelect
                label="Honors Minors Type"
                name="honorsMinorsType"
                control={control}
                options={Object.values(honorsMinorsTypes).map((item) => ({
                  label: item,
                  value: item,
                }))}
                required
              />
            )}
          </>
        )}
        <FormSingleSelect
          label="Course Type"
          name="subjectTypeId"
          control={control}
          options={masterData?.subjectTypes?.map((item) => ({
            label: item?.type,
            value: item?.id,
          }))}
          required
        />
        <FormTextField
          control={control}
          label="Course Name"
          name="name"
          required
        />
        <FormTextField
          control={control}
          label="Course Code"
          name="subjectCode"
          required
        />
        <FormTextField
          name="refCode"
          label="Ref Code"
          required
          control={control}
        />
        <FormTextField
          name="credits"
          label="Credits"
          required
          control={control}
          inputProps={{ type: 'number' }}
        />
        <FormTextField
          name="subOrder"
          label="Course Order in Semester"
          required
          control={control}
          inputProps={{ type: 'number' }}
        />
        <Card sx={{ padding: '5px 1rem' }}>
          <FormSingleCheckbox
            name={'elective'}
            label="Check if this course is an Elective"
            control={control}
          />
        </Card>
        {watch('elective') && (
          <FormTextField
            name="electGrp"
            label="Elective Group"
            required={watch('elective')}
            control={control}
          />
        )}

        <Card sx={{ padding: '5px 1rem' }}>
          <FormSingleCheckbox
            label="Is Audit Course"
            control={control}
            name={'isAuditCourse'}
          />
        </Card>

        <Card sx={{ padding: '5px 1rem' }}>
          <FormSingleCheckbox
            label="Is Satisfactory With External"
            control={control}
            name={'isSatisfactoryWithExternal'}
          />
        </Card>

        <Card sx={{ padding: '5px 1rem' }}>
          <FormSingleCheckbox
            label="Is Satisfactory Course"
            control={control}
            name={'isSatisfactoryCourse'}
          />
        </Card>

        <Card sx={{ padding: '5px 1rem' }}>
          <FormSingleCheckbox
            label="Is Graded Course"
            control={control}
            name={'isGradedCourse'}
          />
        </Card>
        {!watch('isSatisfactoryCourse') && (
          <>
            <Card sx={{ padding: '5px 1rem' }}>
              <FormSingleCheckbox
                label="Has Internal"
                control={control}
                name={'hasInternal'}
              />
            </Card>
            {watch('hasInternal') && (
              <FormTextField
                name="intMax"
                label="Internal Max. Marks"
                required
                control={control}
                inputProps={{ type: 'number' }}
              />
            )}
            <Card sx={{ padding: '5px 1rem' }}>
              <FormSingleCheckbox
                label="Has External"
                control={control}
                name={'hasExternal'}
              />
            </Card>
            {watch('hasExternal') && (
              <FormTextField
                name="extMax"
                label="External Max. Marks"
                required
                control={control}
                inputProps={{ type: 'number' }}
              />
            )}
          </>
        )}
        <Stack direction={'row'} gap={2} mt={2}>
          <ActionButton fullWidth variant="outlined" onClick={close}>
            Cancel
          </ActionButton>
          <ActionButton fullWidth type="submit" loading={updatingSubject}>
            {data ? `Update Course` : 'Create Course'}
          </ActionButton>
        </Stack>
      </Stack>
    </form>
  )
}
