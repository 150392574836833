import { styled } from '@mui/material'

export const headerHeight = '64px'
export const sideNavWidth = '220px'

export const StyledLayoutContainer = styled('div')({
  '& > div': {},
})

export const StyledHeaderContainer = styled('header')({
  zIndex: 300,
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  minHeight: headerHeight,
  background: '#fff',
  boxShadow: '0px 0px 3px #0000001a',
  '& > div': {
    height: headerHeight,
  },
})

export const StyledLeftNavContainer = styled('aside')(({ theme }) => ({
  width: sideNavWidth,
  background: theme.palette.secondary.main,
  color: 'white',
  position: 'fixed',
  top: headerHeight,
  left: 0,
  height: `calc(100vh - ${headerHeight})`,
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: '0.5em',
    height: '0.5em',
  },

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    borderRadius: '3px',

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.4)',
    },
  },

  zIndex: 100,
}))

export const StyledMainContentContainer = styled('main')(() => ({
  width: `calc(100% - ${sideNavWidth})`,
  height: `calc(100vh - ${headerHeight})`,
  position: 'fixed',
  top: headerHeight,
  left: sideNavWidth,
  overflowY: 'auto',
}))
