import { PageNotFound, Permission } from '@campxdev/shared'
import {
  ProfilePermissionsPage,
  ProfilesPage,
  UsersPage,
} from 'pages/ManageUsers'

export const manageUsers = [
  {
    path: 'users',
    element: <UsersPage />,
    permissionKey: Permission.CAN_ADMIN_USERS_VIEW,
  },
  {
    path: 'profiles',
    element: <ProfilesPage />,
    permissionKey: Permission.CAN_MANAGE_USER_PROFILES,
  },
  {
    path: 'profiles/:id',
    element: <ProfilePermissionsPage />,
    permissionKey: Permission.CAN_MANAGE_USER_PROFILES,
  },

  {
    path: '*',
    element: <PageNotFound />,
  },
]
