import { PageNotFound, Permission } from '@campxdev/shared'
import {
  AllCoursesPage,
  CourseBatches,
  CourseBooks,
  CourseDetails,
  CourseObjectives,
  CourseOutcomes,
  CourseTypesPage,
  CourseUnitsAndTopics,
  Handouts,
} from 'pages/Academics'

export const coursesRoutes = [
  // All Courses Course Routes
  {
    path: 'all-courses',
    element: <AllCoursesPage />,
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <CourseDetails />,
    path: 'all-courses/:courseId',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <CourseObjectives />,
    path: 'all-courses/:courseId/objectives',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <CourseOutcomes />,
    path: 'all-courses/:courseId/outcomes',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <CourseBatches />,
    path: 'all-courses/:courseId/subject-batches-config',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_BATCH_CONFIG_VIEW,
  },
  {
    element: <CourseBooks />,
    path: 'all-courses/:courseId/books',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <CourseUnitsAndTopics />,
    path: 'all-courses/:courseId/units-and-topics',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    element: <Handouts />,
    path: 'all-courses/:courseId/handouts',
    permissionKey: Permission.CAN_ADMIN_SUBJECTS_VIEW,
  },
  {
    path: 'course-types',
    element: <CourseTypesPage />,
    permissionKey: Permission.CAN_ADMIN_SUBJECT_TYPES_VIEW,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
