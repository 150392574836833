import {
  AppHeader,
  ErrorBoundary,
  Helmet,
  Spinner,
  useAuth,
} from '@campxdev/shared'
import { Outlet } from 'react-router-dom'
import {
  StyledHeaderContainer,
  StyledMainContentContainer,
} from './styles/mainLayout'

export function AppLayout() {
  const { data, loading } = useAuth({
    permissionsEndpoint: '/auth-server/auth/my-permissions',
  })

  if (loading) {
    return <Spinner height={'100vh'} />
  }

  return (
    <>
      <Helmet
        user={data?.user}
        appTitle="Digital Campus | Campx Admin"
        favicon={data?.assets?.logo_square}
        description={'Campus Administration'}
      />
      <StyledHeaderContainer>
        <AppHeader
          userBoxActions={[]}
          clientLogo={data?.assets?.logo}
          fullName={data?.user?.fullName}
        />
      </StyledHeaderContainer>
      <StyledMainContentContainer>
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </StyledMainContentContainer>
    </>
  )
}
